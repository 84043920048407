<template>
    <SmartLinkItemComponent custom-tag="div" class="card-collection" v-if="model" :id="`_${model.system.id}`"
        :component="!model.system.workflow">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <SmartLinkStandaloneElement custom-tag="h2" class="title" :codename="type.elements.title.codename">
                        {{ model.elements.title.value }}
                    </SmartLinkStandaloneElement>
                    <SmartLinkStandaloneElement custom-tag="div" class="description"
                        :codename="type.elements.description.codename">
                        <RichText :value="model.elements.description.value" />
                    </SmartLinkStandaloneElement>
                </div>
            </div>
            <SmartLinkStandaloneElement custom-tag="div" class="row" :codename="type.elements.cards.codename">
                <CardItem v-if="model?.elements?.cards?.value?.length > 0"
                    v-for="item in pageStore.getListAs<CardItemModel>(model.elements.cards.value)" :model="item" />
            </SmartLinkStandaloneElement>
        </div>
    </SmartLinkItemComponent>
</template>

<script lang="ts" setup>
import { type CardCollectionModel, type CardItemModel, contentTypes } from '~/models';
import type { AnchorNavigationBarItem } from '~/types/AnchorNavigationBarItem';
const type = contentTypes.card_collection
const pageStore = usePageStore();
const props = defineProps<{ model: CardCollectionModel }>();

const navBarState = useState<AnchorNavigationBarItem[]>('navBarState');
const AnchorNavBarOrder = useState<string[]>('AnchorNavBarOrder');

if (multipleChoiceToBoolean(props.model.elements.anchorTagNavigationShowInNavigation)) {

    navBarState.value.push({ text: props.model.elements.anchorTagNavigationMenuTitle.value, link: `#${props.model.system.id}`, index: AnchorNavBarOrder.value.indexOf(props.model.system.codename)});
}
</script>